namespace eh {
  
  export class LinkTarget {
    
    static isExternalAnchor(el : any, treatCorporateWebsitesAsExternal = true): boolean {
      if (el instanceof HTMLAnchorElement) {
        return !!el.hostname && el.hostname !== location.hostname
          && !el.getAttribute('data-lightbox')
          && !el.getAttribute('target')
          && (treatCorporateWebsitesAsExternal || !LinkTarget.isCorporateWebsiteUrl(el.href))
        ;
      }
      return false;
    }
  
    static isCorporateWebsiteUrl(url: string) {
      return new RegExp('^https://(?:(?:(?:(?:live|staging)-[dqp])|www)\\.)?[a-z]{2}(?:sc|w)?\\.endress\\.com(?:\\.cn)?(?::[0-9]{4})?(?:$|/|\\?|#).*').test(url);
    }

    static init($base: JQuery<HTMLElement>): void {
      $('a', $base).filter((i, el) => {
        return LinkTarget.isExternalAnchor(el, false);
      }).attr("target", "_blank");

      $('.eh-trigger-link-target', $base).each((_index, el) => {
        let $linkedArea: JQuery<HTMLElement> = $(el);
        $linkedArea.on('click', function ($event) {
          let $target = $linkedArea.find('.eh-link-target:first'),
              linkTarget = $target.attr('href');
          if ($($event.target).closest('a').length === 1) { // anchor in target chain => clicked directly
            return;
          }
          if ($($event.target).closest('.eh-link-target-exclude').length === 1 || $target.hasClass('disabled')) {
            return;
          }
          if ($target.length !== 1) {
            return;
          }
          if ($target.data('lightbox')) {
            // delegate to lightbox click handler
            $target.triggerHandler('click');
            return;
          }
          $event.preventDefault();
          if (linkTarget && linkTarget !== '#') {
            const windowName = $target.attr('target') || '_self';
            if (windowName === '_self') {
              window.location.href = linkTarget;
            } else {
              window.open(linkTarget, windowName);
            }
          }
          else {
            // generate standard DOM event in order to trigger foreign code, e.g. NeBP
            let event: MouseEvent;
            if (typeof MouseEvent === 'function') {
              event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
              });
            }
            else {
              event = document.createEvent('MouseEvent');
              event.initEvent('click', true, false);
            }
            $target.get(0)?.dispatchEvent(event);
          }
        });
      });
    }
    
  }
  
}

namespace eh {
  /** Controller to manage flyout navigation behaviour. */
  export class NavigationController {

    public static NAV_WRAPPER: string = 'ehel-navigation';
    public static NAV_CLASSNAME: string = 'ehel-navigation--categories';// wrapper lvl1
    public static NAV_CATEGORY_CLASSNAME: string = 'ehel-navigation--links-item-category';// menueitem with sub
    public static NAV_CATEGORY_LINK_CLASSNAME: string = 'ehel-navigation--links-item-category-link';// menuitem without sub
    public static NAV_SUBMENU_CLASSNAME: string = 'ehel-navigation--sub-links-items';// wrapper sub1, sub2 sets
    public static NAV_WRAPPER_CLASSNAME: string = 'ehel-navigation-wrapper';
    public static NAV_HEADER_CANCEL_BUTTON_ID: string = 'nebp-header-navigation';
    public static NAV_HEADER_MOBILE_TARGET_CONTAINER_CLASSNAME: string = 'eh-mobile-header-navigation-target-container';
    public static NAV_HEADER_MOBILE_DESKTOP_CONTAINER_CLASSNAME: string = 'eh-desktop-header-navigation-target-container';
    public static NAV_HEADER_ROW_LEFT_CLASSNAME: string = 'ehel-navigation--row-left'; 
    public static NAV_CANCEL_CLASSNAME: string = 'ehel-show-navigation-cancel';
    public static NAV_MENU_BUTTON: string = 'ehel-navigation--menu-button';
    public static NAV_CLOSE_MENU_BUTTON: string = 'ehel-navigation--close-menu-button';
    public static ICON_CLASSNAME: string = 'ehel-icon-desktop';
    public static NAV_OVERLAY_CLASSNAME: string = 'ehel-navigation--overlay';
    public static PAGE_WRAPPER_CLASSNAME: string = 'eh-page--wrapper';
    public static SEARCH_INPUT_CLASSNAME: string = 'eh-header-search-bar--filter-input';
    public static NAV_META_GENERAL_CLASSNAME: string = 'ehel-navigation--general';
    public static NAV_META_ITEM_GENERAL_CLASSNAME: string = 'ehel-navigation--links-item-general';

    public static uiStateSupport: eh.UIObservableSupport = new UIObservableSupport(UIStateChanged.uiStateMainNav);

    static init($base: JQuery<HTMLElement>): void {
      $(`.${NavigationController.NAV_WRAPPER}`, $base).each((index: number, element: HTMLElement) => {
        new NavigationController(element);
      });
    }

    private el: HTMLElement | null | undefined;
    private pageWrapper: HTMLElement | null | undefined;
    private subnav: HTMLElement | null | undefined;
    private subnavWrapper: HTMLElement | null | undefined;
    private wrapper: HTMLElement | null | undefined;
    private headerNavigationCancelButton: HTMLElement | null | undefined;
    private viewTargetContainerMobile: HTMLElement | null | undefined;
    private viewTargetContainerDesktop: HTMLElement | null | undefined;
    private categories: NodeListOf<HTMLElement> | null | undefined;
    private categoriesLink: NodeListOf<HTMLElement> | null | undefined;
    private navGeneral: HTMLElement | null | undefined;
    private itemsGeneral: NodeListOf<HTMLElement> | null | undefined;
    private icons: NodeListOf<HTMLElement> | null | undefined;
    private cancelBtn: NodeListOf<HTMLElement> | null | undefined;
    private closeBtn: HTMLElement | null | undefined;
    private overlay: HTMLElement | null | undefined;
    private menuBtn: NodeListOf<HTMLElement> | null | undefined;

    private root: HTMLElement = document.scrollingElement as HTMLElement;

    private vm: NavigationViewModel;

    constructor(
      private readonly base: HTMLElement
    ) {
      this.el = this.base.querySelector(`.${NavigationController.NAV_CLASSNAME}`) as HTMLElement;
      this.wrapper = this.base.querySelector(`.${NavigationController.NAV_WRAPPER_CLASSNAME}`) as HTMLElement;
      this.categories = this.base.querySelectorAll(`.${NavigationController.NAV_CATEGORY_CLASSNAME}`);
      this.categoriesLink = this.base?.querySelectorAll(`.${NavigationController.NAV_CATEGORY_LINK_CLASSNAME}`);
      this.navGeneral = this.base?.querySelector(`.${NavigationController.NAV_META_GENERAL_CLASSNAME}`) as HTMLElement;
      this.itemsGeneral = this.base?.querySelectorAll(`.${NavigationController.NAV_META_ITEM_GENERAL_CLASSNAME}`);
      this.icons = this.base.querySelectorAll(`.${NavigationController.NAV_CATEGORY_CLASSNAME} .${NavigationController.ICON_CLASSNAME}`);
      this.subnav = this.base.querySelector(`.${NavigationController.NAV_SUBMENU_CLASSNAME}`) as HTMLElement;
      this.subnavWrapper = this.base?.querySelector(`.${NavigationController.NAV_HEADER_ROW_LEFT_CLASSNAME}`) as HTMLElement;
      this.cancelBtn = this.base.querySelectorAll(`.${NavigationController.NAV_CANCEL_CLASSNAME}`);
      this.closeBtn = this.base?.querySelector(`.${NavigationController.NAV_CLOSE_MENU_BUTTON}`) as HTMLElement;
      this.overlay = this.base?.querySelector(`.${NavigationController.NAV_OVERLAY_CLASSNAME}`) as HTMLElement;
      this.menuBtn = this.base?.querySelectorAll(`.${NavigationController.NAV_MENU_BUTTON}`);
      this.headerNavigationCancelButton = this.base?.querySelector(`#${NavigationController.NAV_HEADER_CANCEL_BUTTON_ID}`) as HTMLElement;
      this.viewTargetContainerMobile = this.base?.querySelector(`.${NavigationController.NAV_HEADER_MOBILE_TARGET_CONTAINER_CLASSNAME}`) as HTMLElement;
      this.viewTargetContainerDesktop = this.base?.querySelector(`.${NavigationController.NAV_HEADER_MOBILE_DESKTOP_CONTAINER_CLASSNAME}`) as HTMLElement;
      this.pageWrapper = document?.querySelector(`.${NavigationController.PAGE_WRAPPER_CLASSNAME}`) as HTMLElement;

      if (!this.el || !this.categories || !this.subnav || !this.cancelBtn || !this.icons || !this.wrapper || !this.cancelBtn || !this.closeBtn
        || !this.headerNavigationCancelButton || !this.viewTargetContainerDesktop || !this.viewTargetContainerMobile || !this.overlay) {
        return;
      }
      this.vm = new NavigationViewModel(
        this.categories,
        this.subnav,
        this.icons,
        this.base,
        this.wrapper,
        this.headerNavigationCancelButton,
        this.viewTargetContainerDesktop,
        this.viewTargetContainerMobile,
        this.overlay,
        this.pageWrapper,
        this.subnavWrapper,
        this.navGeneral
      );
      this.init();
    }

    private init(): void {
      NavigationController.uiStateSupport.init(this.base)
      SiteSearch.uiStateSupport.registerUIStateListener(this.onSearchPanelChange);
      this.registerControls();
    }

    private registerControls(): void {
      this.categories?.forEach(cat => {
        cat.addEventListener('focus', this.doCategoryFocus);
        cat.addEventListener('click', this.doCategoryClick);
      });
      this.categoriesLink?.forEach(cat => {
        cat.addEventListener('mousedown', this.doCategoryMousedown);
        cat.addEventListener('focusout', this.doCategoryFocusout);
      });
 
      this.cancelBtn?.forEach(cat => {
        cat.addEventListener('click', this.onCancelBtnClicked);
      });
      this.closeBtn?.addEventListener('click', this.onCloseBtnClicked);
      this.menuBtn?.forEach(cat => {
        cat.addEventListener('click', this.onMenuBtnClicked);
      });
      this.root.addEventListener('click', this.onScrollPageClicked)

      this.base?.addEventListener('keydown', this.onKeypressed);
      this.itemsGeneral?.forEach(item => {
        item.addEventListener('mousedown', this.doCategoryMousedown);
        item.addEventListener('focusout', this.doCategoryFocusout);
      });
      document.addEventListener('scroll', this.onScrollChange, {
        passive: true,
      });
    }

    private onKeypressed = (e: KeyboardEvent): void => {
      if(eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      let cat;
      if (this.base) {
        cat = $(this.base).find('.ehel-navigation--categories .ehel-navigation--links-item.active a:first');
      }
      if(cat && cat.length > 0) {
        if(["Escape", "Tab"].indexOf(e.code) > -1) {
          this.vm.reset();
        }
        if(!this.vm.isSubOpen()) {
          if(["ArrowDown"].indexOf(e.code) > -1) {
            e.preventDefault();
            e.stopPropagation();
            if (this.vm.showCategory(cat[0])) {
              this.triggerExpanded(cat[0]);
            }
          }
        } else if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Enter"].indexOf(e.code) > -1) {
          e.preventDefault();
          e.stopPropagation();
          this.vm.onKeypressed(cat[0], e.code);
        }
      }
    }

    private doCategoryFocus = (e: UIEvent): void => {
      if(eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      let ctrl = this;
      // do not interfere with clicks, only do open and click be handled first ..
      let ct = e.currentTarget as HTMLElement;
      e.preventDefault();
      e.stopPropagation();
      if(!ctrl.vm.isOpen() && ctrl.isTopLevel(ct)) {
        window.setTimeout(function() {
          if(!ctrl.vm.isOpen() && ctrl.isTopLevel(ct)) {
            ctrl.vm?.reset();
            ctrl.vm?.onResize(ct);
            if (ctrl.vm.showCategory(ct)) {
              ctrl.triggerExpanded(ct);
            }
          }
        }, 200);
      }
    }

    private doCategoryFocusout = (e: UIEvent): void => {
      let ct = e.currentTarget as HTMLElement;
      ct.classList.remove('focus-click');
    }

    private doCategoryMousedown = (e: UIEvent): void => {
      let ct = e.currentTarget as HTMLElement;
      ct.classList.add('focus-click');
    }

    private doCategoryClick = (e: UIEvent): void => {
      let ct = e.currentTarget as HTMLElement;
      e.preventDefault();
      e.stopPropagation();
      if(eh.Breakpoints.getInstance().isMobile) {
        if (this.vm.showCategory(ct)) {
          this.triggerExpanded(ct);
        }
        return;
      }
      const wasOpened = this.isTopLevel(ct) && this.vm?.isCatOpen(ct);
      const submenu = this.base.querySelector('.ehel-navigation--sub-links-items');
      if (submenu) {
        if (wasOpened) {
          submenu.classList.add('ehtw-pointer-events-none');
        } else {
          submenu.classList.remove('ehtw-pointer-events-none');
        }
      }

      if(this.isTopLevel(ct))  {
        this.vm?.reset();
        this.vm?.onResize(ct);
      }
    
      if (!wasOpened && this.vm?.showCategory(ct)) {
        this.triggerExpanded(ct);
      }
    }


    private onCancelBtnClicked = (e: MouseEvent | TouchEvent): void => {
      e.preventDefault();
      this.vm.cancelBtn(e);
    }

    private onCloseBtnClicked = (e: MouseEvent | TouchEvent): void => {
      //e.preventDefault();
      this.vm?.closeBtn(e);
    }

    private onMenuBtnClicked = (e: MouseEvent | TouchEvent): void => {
      //e.preventDefault();
      this.vm?.menuBtn(e);
    }

    private onScrollPageClicked = (e: MouseEvent | TouchEvent): void => {
      let el = e.target as HTMLElement;
      let x = $(el).closest(`.${NavigationController.NAV_CLASSNAME}`);
      if (x.length == 0) {
        this.vm.clickRoot(el);
      }
    }

    private onScrollChange = (): void => {
      if(!eh.Breakpoints.getInstance().isMobile) {
        this.vm?.reset();
      }
    }

    private isTopLevel(elem: HTMLElement): boolean {
      return $(elem).closest(`.${NavigationController.NAV_CLASSNAME}`).length > 0;
    }

    private triggerExpanded(elem: HTMLElement) {
      if(this.isTopLevel(elem) && eh.NAV_FLYOUT_EVENTS) {
        $(':root').trigger(eh.NAV_FLYOUT_EVENTS.FLYOUT_EXPANDED, elem);
      }
    }

    private onSearchPanelChange: (last: UIStateChanged, current: UIStateChanged) => void = (last: UIStateChanged, current: UIStateChanged): void => {
      if(eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      if(current.state == UIStateValue.OPENED) {
        this.vm.reset();
      }
     }
  }

  class NavigationViewModel {

    private static HIDDEN_CLASSNAME: string = 'lg:ehtw-hidden';
    private static BLOCK_CLASSNAME: string = 'lg:ehtw-block';

    private static LEFT_FULL_CLASSNAME: string = 'ehtw-left-full';
    private static LEFT_NONE_CLASSNAME: string = 'ehtw-left-none';

    private static RIGTH_FULL_CLASSNAME: string = '-ehtw-right-full';
    private static RIGHT_NONE_CLASSNAME: string = 'ehtw-right-none';

    private static CAT_OPENED_CLASSNAME: string = 'eh-cat-opened';
    private static CAT_CLOSED_CLASSNAME: string = 'eh-cat-closed';

    private static OVERFLOW_HIDDEN_CLASSNAME: string = '!ehtw-overflow-hidden';

    private static ACTIVE_CLASSNAME: string = 'active';

    private static NAV_WIDTH: number = 420;
    private static NAV_OFFSET: number = 0;
    private static NAV_NUMBER_LEVELS: number = 2;
    private static NAV_RIGHT_OFFSET: number = 56;

    private levels: string[] = [];
    private isNavOpen: boolean = false;
    private isSubMenuOpened = false;

    private currentNavItem: HTMLElement | null = null;

    constructor(
      private categories: NodeListOf<HTMLElement>,
      private subnav: HTMLElement,// wrapper sub1, sub2 sets=ul
      private icons: NodeListOf<HTMLElement>,
      private navigation: HTMLElement,
      private wrapper: HTMLElement,
      private headerNavigationCancelButton: HTMLElement,
      private viewTargetContainerDesktop: HTMLElement,
      private viewTargetContainerMobile: HTMLElement,
      private overlay: HTMLElement,
      private pageWrapper?: HTMLElement,
      private subnavWrapper?: HTMLElement,
      private navGeneral?: HTMLElement

    ) {
      setTimeout(() => {
        this.init();
        }, 20);
    }

    private init(): void {
      eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
      eh.Breakpoints.getInstance().registerResizeListener(this.onResize);
      $(':root').on(HEADER_EVENTS.DOM_MUTATION, this.syncDropdownPosition);
  }

    private onBreakpointChange: (old: Breakpoint | null, current: Breakpoint) => void = (_old: Breakpoint | null, _current: Breakpoint): void => {
      this.reset();
      if (_current.isMobile) {
        this.viewTargetContainerMobile.appendChild(this.headerNavigationCancelButton);
     } else {
       this.viewTargetContainerDesktop.prepend(this.headerNavigationCancelButton);
     }
   };

    public onResize: (currentNavItem?: HTMLElement) => void = (currentNavItem?: HTMLElement): void => {
      //this.reset();
      if (currentNavItem) {
        this.currentNavItem = currentNavItem;
      }
    
      if (this.subnav && this.currentNavItem) {
        let calcL = offsetLeftToBody(this.currentNavItem) - NavigationViewModel.NAV_OFFSET;
        let left = (this.currentNavItem.offsetLeft - NavigationViewModel.NAV_OFFSET);
        let delta = calcL -left;// fixes screens 1440->1441 px
        let minLeft = window.innerWidth - NavigationViewModel.NAV_WIDTH * NavigationViewModel.NAV_NUMBER_LEVELS - NavigationViewModel.NAV_RIGHT_OFFSET -delta;
        this.subnav.style.left = (left > minLeft ? minLeft : left)+ 'px';
      }
    };

    private syncDropdownPosition: (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics) => void = (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics): void => {
      if (this.subnavWrapper) {
        this.subnav.style.top = `${this.subnavWrapper.offsetHeight + (metrics.headerStickyState || !this.navGeneral ? 0 : this.navGeneral?.offsetHeight)}px`;
      }
      this.overlay.style.top = `${metrics.visibleHeaderHeight + metrics.visibleDisplaceElemHeight}px`;
      if (this.pageWrapper && !metrics.headerStickyState) {
        this.pageWrapper.style.marginTop = `${metrics.visibleHeaderHeight}px`;
      }
      const stikyElements = document.getElementsByClassName('is-sticky');
      if (stikyElements.length > 1) {
        const header = $(this.navigation).find('header');
        header.removeClass('has-shadow');
        header.removeClass('ehtw-shadow-fly');
      }
    };

    public isOpen():boolean {
      return this.isNavOpen;
    }
    public isSubOpen() {
      return this.isSubMenuOpened;
    }

    public isCatOpen(elem: HTMLElement): boolean {
      const icon = elem.querySelector(`.${NavigationController.ICON_CLASSNAME}`);
      if (icon) {
        return icon.classList.contains(NavigationViewModel.CAT_OPENED_CLASSNAME);
      }
      return false;
    }

    public reset() {
      this.icons.forEach(icon => {
        icon.classList.add(NavigationViewModel.CAT_CLOSED_CLASSNAME);
        icon.classList.remove(NavigationViewModel.CAT_OPENED_CLASSNAME);
      });
      this.categories.forEach(cat => {
        cat.classList.remove(NavigationViewModel.ACTIVE_CLASSNAME);
        const parent = cat?.closest('li');
        if (parent) {
          parent.classList.remove(NavigationViewModel.ACTIVE_CLASSNAME);
        }
      });

      const submenus = this.subnav.querySelectorAll(`ul`);
      submenus.forEach(submenu => {
        submenu.classList.add(NavigationViewModel.HIDDEN_CLASSNAME);
        submenu.classList.add(NavigationViewModel.LEFT_FULL_CLASSNAME);
        submenu.classList.add(NavigationViewModel.RIGTH_FULL_CLASSNAME);

        submenu.classList.remove(NavigationViewModel.LEFT_NONE_CLASSNAME);
        submenu.classList.remove(NavigationViewModel.RIGHT_NONE_CLASSNAME);
      });
      this.isSubMenuOpened = false;

      const siteEl: JQuery<Element> = eh.ScrollPage.getScrollRoot();
      const toggleNaviClassName: string = 'eh-navigation-active';
      const isOpen: boolean = siteEl.hasClass(toggleNaviClassName);

      if (isOpen) {
        eh.ScrollPage.setScrollEnabled(isOpen);
        siteEl.toggleClass(toggleNaviClassName, !isOpen);
      }
      //eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll');
      this.wrapper.classList.remove(NavigationViewModel.OVERFLOW_HIDDEN_CLASSNAME);
      this.levels = [];
      this.overlay.classList.remove(NavigationViewModel.BLOCK_CLASSNAME);

      if(this.isNavOpen) {
        eh.NavigationController.uiStateSupport.dispatch(UIStateValue.CLOSED);
      }
      this.isNavOpen = false;
    }

    public showCategory(cat: HTMLElement | null): boolean {
      //eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll');
      this.wrapper.classList.add(NavigationViewModel.OVERFLOW_HIDDEN_CLASSNAME);

      this.overlay.classList.add(NavigationViewModel.BLOCK_CLASSNAME);
      
      if (cat?.dataset['navigationMenu'] && !this.levels.includes(cat.dataset['navigationMenu'] as string)) {
        this.isSubMenuOpened = true;
        const icon = cat.querySelector(`.${NavigationController.ICON_CLASSNAME}`);
        if (icon) {
          icon.classList.remove(NavigationViewModel.CAT_CLOSED_CLASSNAME);
          icon.classList.add(NavigationViewModel.CAT_OPENED_CLASSNAME);
        }

        if (!cat.closest(`.ehel-navigation--sub-links-item-2`)) {
          this.hideLevel3(null);
        }  else {
          this.subnav.querySelectorAll(`.active`).forEach(sn =>  sn.classList.remove(NavigationViewModel.ACTIVE_CLASSNAME));
        }
        const submenus = this.subnav.querySelectorAll(`[data-sub-navigation-menu="${cat?.dataset['navigationMenu']}"`) as NodeListOf<Element>;
        const desksize = !eh.Breakpoints.getInstance().isMobile;
        if(submenus && submenus.length > 0) {
          submenus.forEach((submenu: Element) => {
            if(desksize || !submenu.classList.contains('marker-nav-overview')) {
              submenu.classList.remove(NavigationViewModel.LEFT_FULL_CLASSNAME);
              submenu.classList.remove(NavigationViewModel.RIGTH_FULL_CLASSNAME);
              submenu.classList.remove(NavigationViewModel.HIDDEN_CLASSNAME);
              submenu.classList.add(NavigationViewModel.LEFT_NONE_CLASSNAME);
              submenu.classList.add(NavigationViewModel.RIGHT_NONE_CLASSNAME);
            }
          });
        }
        cat.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
        const parent = cat.closest('li');
        if (parent) {
          parent.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
        }
        this.levels.push(cat.dataset['navigationMenu'] as string);
        if(!this.isNavOpen) {
          eh.NavigationController.uiStateSupport.dispatch(UIStateValue.OPENED);
        }
        this.isNavOpen = true;
        this.openOverView();
        return true;
      } 
      else {
        if (cat && !cat.closest(`.ehel-navigation--sub-links-item-2`)) {
          this.hideLevel3(null);
          const parentCat = cat.closest(`.ehel-navigation--sub-links-item-1`) as HTMLElement;
          if (parentCat) {
            const submenus = this.subnav.querySelectorAll(`[data-sub-navigation-menu="${parentCat?.dataset['subNavigationMenu']}"`) as NodeListOf<Element>;
            if(submenus && submenus.length > 0) {
              submenus.forEach((submenu: Element) => {
                if(!submenu.classList.contains('marker-nav-overview')) {
                  submenu.classList.remove(NavigationViewModel.LEFT_FULL_CLASSNAME);
                  submenu.classList.remove(NavigationViewModel.RIGTH_FULL_CLASSNAME);
                  submenu.classList.remove(NavigationViewModel.HIDDEN_CLASSNAME);
                  submenu.classList.add(NavigationViewModel.LEFT_NONE_CLASSNAME);
                  submenu.classList.add(NavigationViewModel.RIGHT_NONE_CLASSNAME);
                }
              });
            }
          }
        } else {
          this.subnav.querySelectorAll(`.active`).forEach(sn =>  sn.classList.remove(NavigationViewModel.ACTIVE_CLASSNAME));
        }
        cat?.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
        const parent = cat?.closest('li');
        if (parent) {
            parent.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
        }
      }
      if(!this.isNavOpen) {
        eh.NavigationController.uiStateSupport.dispatch(UIStateValue.OPENED);
      }
      this.isNavOpen = true;
      this.openOverView();
      return false;
    }

    // open 'marker-nav-overview' if subNav is opened and subSubNav not available
    // COM-1847
    private openOverView():void {
      setTimeout(()=> {
        const desksize = !eh.Breakpoints.getInstance().isMobile;
        if(this.levels.length > 0 && desksize) {
          if(!this.findOpenSub() || this.findOpenSubSub()) {
            return;// ui state wrong
          }
          let active = this.findOpenSub()?.querySelector('a.active') as HTMLElement;
          if(active?.dataset['navigationMenu']) {
            return;// subsub available
          }
          const subm = this.subnav.querySelectorAll(`[data-sub-navigation-menu="${this.levels[0]}"`) as NodeListOf<Element>;
          if(subm && subm.length > 0) {
            subm.forEach((submenu: Element) => {
              if(submenu.classList.contains('marker-nav-overview')) {
                submenu.classList.remove(NavigationViewModel.LEFT_FULL_CLASSNAME);
                submenu.classList.remove(NavigationViewModel.RIGTH_FULL_CLASSNAME);
                submenu.classList.remove(NavigationViewModel.HIDDEN_CLASSNAME);
                submenu.classList.add(NavigationViewModel.LEFT_NONE_CLASSNAME);
                submenu.classList.add(NavigationViewModel.RIGHT_NONE_CLASSNAME);
              }
            });
          }
        }
      });
    }

    private findOpenOverview(): HTMLElement | null {
      let l1 = this.subnav.querySelectorAll(`:not(.lg\\:ehtw-hidden).marker-nav-overview.${NavigationViewModel.LEFT_NONE_CLASSNAME}.${NavigationViewModel.LEFT_NONE_CLASSNAME}`);
      if(l1 && l1.length) {
        return l1[0] as HTMLElement;
      }
      return null;
    }

    private findOpenSub(): HTMLElement | null {
      let l1 = this.subnav.querySelectorAll(`.ehel-navigation--sub-links-item-1.${NavigationViewModel.LEFT_NONE_CLASSNAME}.${NavigationViewModel.LEFT_NONE_CLASSNAME}`);
      if(l1 && l1.length) {
        return l1[0] as HTMLElement;
      }
      return null;
    }

    private findOpenSubSub(): HTMLElement | null {
      let l2 = this.subnav.querySelectorAll(`:not(.lg\\:ehtw-hidden).ehel-navigation--sub-links-item-2.${NavigationViewModel.LEFT_NONE_CLASSNAME}.${NavigationViewModel.LEFT_NONE_CLASSNAME}`);
      if(l2 && l2.length) {
        return l2[0] as HTMLElement;
      }
      return null;
    }

    public hideLevel3(elem: HTMLElement | null) {
      this.subnav.querySelectorAll(`.ehel-navigation--sub-links-item-2`).forEach(sn => {
        sn.classList.add(NavigationViewModel.HIDDEN_CLASSNAME);
        if(!sn.classList.contains('marker-nav-overview')) {
          this.levels = this.levels.filter(x => x !== (sn as HTMLElement).dataset['subNavigationMenu']);
        }
      });
      this.subnav.querySelectorAll(`.active`).forEach(sn => sn.classList.remove(NavigationViewModel.ACTIVE_CLASSNAME));
    }

    public cancelBtn(e: MouseEvent | TouchEvent) {
      if (this.levels.length > 0) {
        const level = this.levels.pop();
        const submenus = this.subnav.querySelectorAll(`[data-sub-navigation-menu="${level}"`) as NodeListOf<Element>;
        if(submenus && submenus.length > 0) {
          submenus.forEach((submenu: Element) => {
            submenu.classList.add(NavigationViewModel.LEFT_FULL_CLASSNAME);
            submenu.classList.add(NavigationViewModel.RIGTH_FULL_CLASSNAME);
            submenu.classList.remove(NavigationViewModel.LEFT_NONE_CLASSNAME);
            submenu.classList.remove(NavigationViewModel.RIGHT_NONE_CLASSNAME);
          });
        }
        if (this.levels.length === 0) {
          this.wrapper.classList.remove(NavigationViewModel.OVERFLOW_HIDDEN_CLASSNAME);
        }
      } else {
        this.reset();
      }
    }

    public closeBtn(e: MouseEvent | TouchEvent) {
      const submenu = this.navigation?.querySelector('.ehel-navigation--sub-links-items');
      if (submenu) {
        submenu.classList.add('ehtw-pointer-events-none');
    }
    }

    public menuBtn(e: MouseEvent | TouchEvent) {
      const submenu = this.navigation?.querySelector('.ehel-navigation--sub-links-items');
      if (submenu) {
        if (this.isOpen()) {
          submenu.classList.add('ehtw-pointer-events-none');
        } else {
          submenu.classList.remove('ehtw-pointer-events-none');
        }
      }
    }

    public clickRoot(elem: HTMLElement | null) {
      const subnav = elem?.closest('.ehel-navigation--sub-links-items');
      if (!subnav && this.isSubMenuOpened) {
        this.reset();
      }
    }

    public onKeypressed(cat: HTMLElement, code: string): HTMLElement | null {
      const activeElems = this.subnav.querySelectorAll(`li.${NavigationViewModel.ACTIVE_CLASSNAME}`);
      let activeElem;
      if (activeElems && activeElems.length) {
        activeElem = activeElems[activeElems.length - 1];
      }
      if (activeElem) {
        let element: HTMLElement | undefined | null = null;
        let expanded: HTMLElement | undefined | null = null;
        switch (code) {
          case 'ArrowUp':
            if((activeElem as HTMLElement).classList.contains('ehjs-navigate-all')) {
              const submenu = (activeElem as HTMLElement).closest('.ehel-navigation--sub-links-item-2');
              let all = submenu?.querySelectorAll('li');
              if(all && all.length) {
                element = all[all.length-1];
              }
            } else if(activeElem.previousElementSibling) {
              if(!((activeElem.previousElementSibling as HTMLElement)?.classList.contains('ehjs-navigate-all')
                && !(activeElem.previousElementSibling as HTMLElement)?.classList.contains('ehjs-navigation-cancel'))) {
                  element = (activeElem.previousElementSibling as HTMLElement)?.querySelector('a');
              }
            }
            break;
          case 'ArrowDown':
            if (!(activeElem as HTMLElement)?.classList.contains('ehjs-navigate-all')) {
              if(activeElem.nextElementSibling instanceof HTMLElement) {
                element = (activeElem.nextElementSibling as HTMLElement)?.querySelector('a');
              } else {
                element = ((activeElem.parentElement as HTMLElement)?.querySelector(`.ehjs-navigate-all`) as HTMLElement)?.querySelector('a');
                if(element) {
                  element.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
                  element.closest('li')?.classList.add(NavigationViewModel.ACTIVE_CLASSNAME);
                }
              }
            }
            break;
          case 'ArrowLeft':
            const submenu = (activeElem as HTMLElement).closest('.ehel-navigation--sub-links-item-2');
            if (submenu) {
              if(submenu.classList.contains('marker-nav-overview')) {
                let t = this.findOpenSub();
                element = this.findOpenSub()?.querySelector('li:not(.ehjs-navigation-cancel)') as HTMLElement;
              } else {
                element = this.subnav.querySelector(`[data-navigation-menu="${(submenu as HTMLElement)?.dataset['subNavigationMenu']}"`) as HTMLElement;
              }
            }
            break;
          case 'ArrowRight':
            let aElement = (activeElem as HTMLElement).querySelector('a');
            if (aElement) {
              const submenu = this.subnav.querySelector(`[data-sub-navigation-menu="${aElement?.dataset['navigationMenu']}"`) as HTMLElement;
              if(submenu) {
                let firstChild = submenu?.querySelectorAll('li')[1];
                if(firstChild?.classList.contains('ehtw-order-first')) {
                  firstChild = submenu?.querySelectorAll('li')[2];
                }
                if (firstChild) {
                  if (this.levels.length <= activeElems.length && this.showCategory(aElement as HTMLElement)) {
                    expanded = aElement;
                  }
                  element = firstChild?.querySelector('a');
                }
              } 
            }
            if(!element && (activeElem as HTMLElement).closest('.ehel-navigation--sub-links-item-1')) {
              let overview = this.findOpenOverview();
              if(overview) {
                element = overview.querySelector('li a') as HTMLElement;
              }
            }
            break;
          case 'Enter':
            let href = $(activeElem).find('a').attr('href');
            if(href && href !== '#') {
              window.location.href = href;
            }
            break;
        }
        if (element && this.showCategory(element as HTMLElement)) {
          return expanded || element;
        }
      }
      else if(code == 'ArrowDown') {
        if(cat?.dataset['navigationMenu']) {
          const submenu = this.subnav.querySelector(`[data-sub-navigation-menu="${cat.dataset['navigationMenu']}"`) as HTMLElement;
          const firstChild = submenu?.querySelectorAll('li')[1];
          if (firstChild) {
            const aElement = firstChild?.querySelector('a');
            if (aElement) {
              if(this.showCategory(aElement)) {
                return aElement;
              }
            }
          }
        }
      }
      return null;
    }

  }
}
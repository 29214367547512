namespace eh {
  
  /**
   * Fetches prices of products from webservice and displays them via widgets placed into the DOM
   *
   */
  export class ProductPricing {
    
    public static readonly EventIdProductPriceUpdate = 'ehProductPricing:productPriceUpdate';
    public static readonly DATA_KEY = 'ehProductPricing';
    public static readonly SELECTOR_LAZY_MARKER = '.marker-product-pricing-lazy';
    public static readonly SELECTOR_LAZY_TRIGGER_LOAD = '.trigger-product-pricing-load';
    public static readonly CLASS_HIDDEN = 'eh--hide';
    
    static readonly logger = log.getLogger('eh.ProductPricing');

    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
      if (isSnippetRequest) {
        return;
      }
      const $productPricingRoot = $('body', $base);
      if ($productPricingRoot.length === 0) {
        return;
      }

      const config: ProductPricingConfiguration = $productPricingRoot.data('productPricingConfig');
      if (!config || Object.keys(config).length === 0) {
        return;
      }

      const pricing = new ProductPricing(config);
      $productPricingRoot.data(ProductPricing.DATA_KEY, pricing);

      $base.on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace): void => {
        pricing.fetchPrices(pricing.findPricePlaceholders($event.replacedTarget).filter(p => p.getType() === PlaceholderType.Price));

        const $lazyContainer = $(ProductPricing.SELECTOR_LAZY_MARKER, $event.replacedTarget);
        $(ProductPricing.SELECTOR_LAZY_TRIGGER_LOAD, $event.replacedTarget)
          .one('click', _$event => {
          pricing.fetchPrices(pricing.findPricePlaceholders($lazyContainer).filter(p => p.getType() === PlaceholderType.Price), true);
        });
      })

      /*
        QuickSelect
        - displays from-prices and scale at beginning
        - displays configured-price and scale when ordercode is completed
        - displays from-prices and scale again if config is changed
       */
      $(eh.QuickSelect.SELECTOR, $base).each((_index, el) => {
        const $quickSelectRoot = $(el);
        const qs = $quickSelectRoot.data(eh.QuickSelect.DATA_KEY) as eh.QuickSelect;
        if (!qs) {
          // can't do anything if quickselect is missing
          return;
        }

        $quickSelectRoot.on(eh.QS_EVENTS.ORDER_CODE_COMPLETE, (_$event: JQuery.TriggeredEvent) => {
          const selectedOptions: MaterialOptions = {o: qs.getSelectedOptions()};
          pricing.fetchPricesConfigured(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Price), true,
            selectedOptions, qs.getSelectedChildProduct()?.materialNumber);
          pricing.fetchPricesForOptions(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Option), true);

          $quickSelectRoot.one(eh.QS_EVENTS.ORDER_CODE_LEGEND_CHANGE, (_$event: JQuery.TriggeredEvent, data: {legend: IProductSegment[]}) => {
            // prevent execution of fetchPrices when fetchPricesConfigured is in charge
            if (data.legend.some((segment: IProductSegment): boolean => segment.isLastLayer)) {
              return;
            }
            // restore un-configured price once on next quickselect change
            pricing.fetchPrices(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Price), true,
              qs.getSelectedChildProduct()?.materialNumber);
          });
        }).on(eh.QS_EVENTS.ORDER_CODE_LEGEND_CHANGE, (_$event: JQuery.TriggeredEvent, data: {legend: IProductSegment[]}) => {
          if (data.legend.length === 1) { // very first quickselect step
            // refresh price after family product was selected
            pricing.fetchPrices(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Price), true,
              qs.getSelectedChildProduct()?.materialNumber);
          }
        }).on(eh.QS_EVENTS.ORDER_CODE_CHANGE, (_$event: JQuery.TriggeredEvent) => {
          pricing.fetchPricesForOptions(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Option), true);
        }).on(eh.QS_EVENTS.INIT, (_$event: JQuery.TriggeredEvent) => {
          pricing.fetchPricesForOptions(pricing.findPricePlaceholders($quickSelectRoot).filter(p => p.getType() === PlaceholderType.Option), true,
            qs.getSelectedChildProduct()?.materialNumber);
        });
      });
  
      pricing.fetchPrices(pricing.findPricePlaceholders($base).filter(p => p.getType() === PlaceholderType.Price));
      pricing.fetchPricesPreConfigured(pricing.findPricePlaceholders($base).filter(p => p.getType() === PlaceholderType.PricePreConfigured));
    }

    protected service: PricingService;

    constructor(private config: ProductPricingConfiguration) {
      this.service = new PricingService(config, eh.NebpProxy.getInstance().getToken.bind(eh.NebpProxy.getInstance()));
    }

    public findPricePlaceholders($container: JQuery<HTMLElement>): PricePlaceholder[] {
      const placeholders: PricePlaceholder[] = [];
      $(PricePlaceholder.SELECTOR, $container).each((_index, el) => {
        const $el = $(el);
        const placeholder = $el.data(PricePlaceholder.DATA_KEY) ?? PricePlaceholder.create($el, this.service.getConfig());
        if (placeholder) {
          placeholders.push(placeholder);
        }
      });
      return placeholders;
    }

    /*
    Takes material numbers of given placeholders and shows (unconfigured) from-prices fetched from service
    Considers placeholders only once or if refresh is requested.
    Material number might be overridden if a specific family product is selected.
     */
    public fetchPrices(placeholders: PricePlaceholder[], refresh: boolean = false, materialNumberOverwrite?: string) {
      placeholders
        .filter(placeholder => refresh || placeholder.getState() === PricePlaceholderState.INITIAL)
        .forEach(placeholder => {
          this.service.getPrice(materialNumberOverwrite ?? placeholder.getMaterialNumber(),
            placeholder.getPriceVisibility()).then(
            (serviceResponse: PriceServiceResponse) => {
              placeholder.showPrice(serviceResponse, false, materialNumberOverwrite);
            })
            .catch(reason => {
              placeholder.showError(reason);
          });
          placeholder.startLoading();
        });
    }

    /*
    Takes material numbers of given placeholders and shows configured-prices for given options fetched from service
     */
    public fetchPricesConfigured(placeholders: PricePlaceholder[], refresh: boolean = false, options: MaterialOptions, materialNumberOverwrite?: string) {
      placeholders
          .filter(placeholder => refresh || placeholder.getState() === PricePlaceholderState.INITIAL)
        .forEach(placeholder => {
          this.service.getPriceConfigured(materialNumberOverwrite ?? placeholder.getMaterialNumber(),
            placeholder.getPriceVisibility(), options).then(
            (serviceResponse: PriceServiceResponse) => {
              placeholder.showPrice(serviceResponse, true, materialNumberOverwrite);
            })
            .catch(reason => {
              placeholder.showError(reason);
            });
          placeholder.startLoading();
        });
    }
  
    public fetchPricesPreConfigured(placeholders: PricePlaceholder[], refresh: boolean = false) {
      placeholders
          .filter(placeholder => refresh || placeholder.getState() === PricePlaceholderState.INITIAL)
          .forEach(placeholder => {
            this.service.getPriceConfigured(placeholder.getMaterialNumber(),
                placeholder.getPriceVisibility(), placeholder.getPreConfiguredOptions()).then(
                (serviceResponse: PriceServiceResponse) => {
                  placeholder.showPrice(serviceResponse, true);
                })
                .catch(reason => {
                  placeholder.showError(reason);
                });
            placeholder.startLoading();
          });
    }

    public fetchPricesForOptions(placeholders: PricePlaceholder[], refresh: boolean = false, materialNumberOverwrite?: string) {
      placeholders
          .filter(placeholder => refresh || placeholder.getState() === PricePlaceholderState.INITIAL)
          .forEach(placeholder => {
            this.service.getPriceForOptions(materialNumberOverwrite ?? placeholder.getMaterialNumber(),
                placeholder.getPriceVisibility()).then(
                (serviceResponse: PriceServiceResponse) => {
                  placeholder.showPrice(serviceResponse, false, materialNumberOverwrite);
                })
                .catch(reason => {
                  placeholder.showError(reason);
                });
            placeholder.startLoading();
          });
    }

    public static isOptionStandard(option: PriceServiceResponse_OptionPrice | undefined): option is PriceServiceResponse_OptionPrice_Standard {
      return option !== undefined
        && (option as PriceServiceResponse_OptionPrice_Standard).v !== undefined
        && (option as PriceServiceResponse_OptionPrice_Standard).v !== 'null'
        && (option as PriceServiceResponse_OptionPrice_Dependend).l === undefined
        && (option as PriceServiceResponse_OptionPrice_Dependend).u === undefined;
    }

    public static isOptionDependend(option: PriceServiceResponse_OptionPrice | undefined): option is PriceServiceResponse_OptionPrice_Dependend {
      return option !== undefined
        && (option as PriceServiceResponse_OptionPrice_Standard).v !== undefined
        && (option as PriceServiceResponse_OptionPrice_Standard).v !== 'null'
        && (option as PriceServiceResponse_OptionPrice_Dependend).u !== undefined;
    }

    public static isOptionNoPrice(option: PriceServiceResponse_OptionPrice_NoPrice | undefined): option is PriceServiceResponse_OptionPrice_NoPrice {
      return option !== undefined
        && ((option as PriceServiceResponse_OptionPrice_NoPrice).v === 'null'
        || (option as PriceServiceResponse_OptionPrice_NoPrice).v === undefined);
    }

    public static isMaterialResult(option: PriceServiceResponse_Material | PriceServiceResponse_Failure | undefined): option is PriceServiceResponse_Material {
      return option !== undefined
        && (option as PriceServiceResponse_Material).price !== undefined;
    }

    public static isOptionsResult(result: PriceServiceResponse_Material | PriceServiceResponse_Options | PriceServiceResponse_Failure | undefined): result is PriceServiceResponse_Options {
      return result !== undefined
        && Array.isArray((result as PriceServiceResponse_Options).options);
    }

  }
  
 
  enum PlaceholderType {
    Price,
    Option,
    PricePreConfigured,
    PriceMirror
  }

  class PricePlaceholder {

    public static readonly DATA_KEY = 'ehPricePlaceholder';
    public static readonly SELECTOR = '.marker-price-placeholder';

    private state: PricePlaceholderState;

    private constructor(private readonly $el: JQuery<HTMLElement>,
                        private readonly type: PlaceholderType,
                        private readonly materialNumber: string,
                        private readonly widget: PriceWidget,
                        private readonly config: ProductPricingConfiguration,
                        private readonly priceVisibility: PriceVisibility,
                        lazy: boolean) {
      this.state = lazy ? PricePlaceholderState.INITIAL_LAZY : PricePlaceholderState.INITIAL;
    }

    public static create($el: JQuery<HTMLElement>, config: ProductPricingConfiguration): PricePlaceholder | undefined {
      const priceVisibility: PriceVisibility | undefined = PriceVisibility[$el.selfOrClosest('*[data-price-visibility]')
        .data('priceVisibility') as keyof typeof PriceVisibility];
      if ([PriceVisibility.ANONYMOUS, PriceVisibility.LOGGED_IN].indexOf(priceVisibility) === -1) {
        return;
      }
      const materialNumber: string | undefined = $el.selfOrClosest('*[data-cs-product-material-number]')
        .data('csProductMaterialNumber');
      if (!materialNumber) {
        return;
      }
      let widget: PriceWidget | undefined;
      let $widgetEl;
      const isPreConfigured = $el.data('pricePreConfigured') === true;
      let placeholderType = isPreConfigured ? PlaceholderType.PricePreConfigured : PlaceholderType.Price;
      $widgetEl = $el.selfOrFind('.eh-price-amount-control').emptyToOptional();
      if (!widget && $widgetEl) {
        widget = new PriceAmountControl($widgetEl, materialNumber, config);
      }
      $widgetEl = $el.selfOrFind('.eh-price-widget').emptyToOptional();
      if (!widget && $widgetEl) {
        if ($widgetEl.selfOrFind(PriceAmountMirrorWidget.SELECTOR_MIRROR).emptyToOptional()) {
          widget = new PriceAmountMirrorWidget($widgetEl, materialNumber, !isPreConfigured);
          placeholderType = PlaceholderType.PriceMirror;
        } else {
          widget = new PriceDisplayWidget($widgetEl);
        }
      }
      $widgetEl = $el.selfOrFind('.eh-price-option-widget').emptyToOptional();
      if (!widget && $widgetEl) {
        widget = new PriceOptionDisplayWidget($widgetEl);
        placeholderType = PlaceholderType.Option;
      }
      if (!widget) {
        return;
      }
      const isInsideLazyContainer = $el.selfOrClosest(ProductPricing.SELECTOR_LAZY_MARKER).length > 0;
      const placeholder = new PricePlaceholder($el, placeholderType, String(materialNumber), widget, config, priceVisibility, isInsideLazyContainer);
      $el.data(PricePlaceholder.DATA_KEY, placeholder);
      return placeholder;
    }

    public getMaterialNumber(): string {
      return this.materialNumber;
    }

    public getOption(): string | undefined {
      return this.$el.data('option');
    }
    
    public getPreConfiguredOptions(): MaterialOptions {
      return {
        o: $.map($('.marker-product-pricing-option', this.$el), (el) => '' + $(el).data('productPricingOption'))
      };
    }
    
    public getPriceVisibility(): PriceVisibility {
      return this.priceVisibility;
    }

    public getState(): PricePlaceholderState {
      return this.state;
    }

    public getType(): PlaceholderType {
      if (this.type === PlaceholderType.PriceMirror) {
        if ((this.widget as PriceAmountMirrorWidget).isMimicPriceDisplayWidget()) {
          return PlaceholderType.Price;
        }
      }
      return this.type;
    }

    public startLoading() {
      if ([PricePlaceholderState.INITIAL,
        PricePlaceholderState.UNAVAILABLE,
        PricePlaceholderState.INITIAL_LAZY].indexOf(this.state) === -1) {
        return;
      }
      this.widget.setLoading();
      this.state = PricePlaceholderState.LOADING;
    }

    public showNoPrice() {
      this.widget.setNoPrice();
      this.state = PricePlaceholderState.NO_PRICE;
    }

    public showError(reason:any = undefined) {
      this.widget.setDebugInfo('error', reason?.toString());
      this.widget.setUnavailable();
      this.state = PricePlaceholderState.UNAVAILABLE;
    }

    private filterResultByMaterialNumber(res: Array<PriceServiceResponse_Material | PriceServiceResponse_Options | PriceServiceResponse_Failure> | undefined, materialNumber: string) {
      return Array.isArray(res) ? res.filter(result => result.matnr === materialNumber)[0] : undefined;
    }

    private getUnitLabel(unit: string) {
      const unitKey = unit === 'zl' ? 'INCH' : unit.toUpperCase();
      return this.config.unitLabels[unitKey] ?? unit;
    }

    public showPrice(serviceResponse: PriceServiceResponse, isConfigured = false, materialNumberOverwrite?: string) {
      const priceInfo: PriceServiceResponse_Material | PriceServiceResponse_Options | PriceServiceResponse_Failure | undefined =
          this.filterResultByMaterialNumber(serviceResponse.rsp?.res, materialNumberOverwrite ?? this.getMaterialNumber());

      if (ProductPricing.isOptionsResult(priceInfo)) {
        const optionValue = this.getOption();

        const optionInfo: PriceServiceResponse_OptionPrice | undefined = priceInfo.options.filter(option => option.o === optionValue)[0];
        if (this.widget instanceof PriceOptionDisplayWidget) {
          if (ProductPricing.isOptionDependend(optionInfo)) {
            const price = parseFloat(optionInfo.v);
            const unitLabel = this.getUnitLabel(optionInfo.u);
            this.widget.setPrice(
                new Intl.NumberFormat(this.config.priceFormatLocale,{ style: 'currency', currency: optionInfo.c }).format(price)
                + ' / '
                + new Intl.NumberFormat(this.config.priceFormatLocale).format(optionInfo.l)
                + ' '
                + unitLabel);
            this.state = PricePlaceholderState.SHOWING_PRICE;
          }
          else if (ProductPricing.isOptionStandard(optionInfo)) {
            const price = parseFloat(optionInfo.v);
            this.widget.setPrice(new Intl.NumberFormat(this.config.priceFormatLocale,
              { style: 'currency', currency: optionInfo.c }).format(price)
            );
            this.state = PricePlaceholderState.SHOWING_PRICE;
          }
          else if (ProductPricing.isOptionNoPrice(optionInfo)) {
            const price = 0.0;
            this.widget.setPrice(new Intl.NumberFormat(this.config.priceFormatLocale,
              {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price)
            );
            this.state = PricePlaceholderState.NO_PRICE;
          }
        }
      }
      else if (ProductPricing.isMaterialResult(priceInfo)) {
        if (this.widget instanceof PriceDisplayWidget) {
          const price = parseFloat(priceInfo?.price);
          this.widget.setPrice(isConfigured ? '' : this.config.fromPricePrefix,
            new Intl.NumberFormat(this.config.priceFormatLocale,
              { style: 'currency', currency: priceInfo?.currency }).format(price)
          );
          this.state = PricePlaceholderState.SHOWING_PRICE;
        }
        else if (this.widget instanceof PriceAmountControl) {
          this.widget.setPrice(priceInfo, isConfigured); // should quickselect be notified?
          
          if (priceInfo?.scale) {
            const $qsElem = this.$el.selfOrClosest(QuickSelect.SELECTOR);
            const qs = $qsElem.data(QuickSelect.DATA_KEY) as QuickSelect;
            const priceScaleLegend = qs ? qs.priceScaleLegend : new QSPriceScaleLegend($(QSPriceScaleLegend.CONTAINER_CLASS_NAME, $qsElem));
          
            const priceScaleCropped: PriceServiceResponse_Scale[] = priceInfo.scale
            .filter((scale, index) => {
              return index < this.config.priceScaleMaxLevel;
            });
            const priceScaleFormatted = priceScaleCropped.map((scale, index) => {
              const price = parseFloat(scale.p.v);
              const currency = scale.p.c;
              const nextScale: PriceServiceResponse_Scale | undefined = priceScaleCropped?.[index + 1];
              const nextQty = parseInt(nextScale?.fromqty ?? '', 10);
              return {
                'range': `${scale.fromqty}${isNaN(nextQty) ? '+' : ' - ' + (nextQty - 1)} ${this.config.piecesLabel}`,
                'price': new Intl.NumberFormat(this.config.priceFormatLocale,
                    { style: 'currency', currency: currency }).format(price)
              };
            });
            priceScaleLegend.update(priceScaleFormatted);
          }
          this.state = PricePlaceholderState.SHOWING_PRICE;
        }
      }
      else if (priceInfo?.code === '401') {
        this.widget.setUnavailable();
        this.widget.setDebugInfo(priceInfo?.code, priceInfo?.msg);
        this.state = PricePlaceholderState.UNAVAILABLE;
      }
      else if (priceInfo?.code === '403') {
        eh.NebpProxy.getInstance().getRegistrationState()
          .then(registrationState => {
            switch (registrationState) {
              case RegistrationState.IN_PROGRESS:
                this.widget.setRegistrationInProgress();
                this.state = PricePlaceholderState.REGISTRATION_IN_PROGRESS;
                break;
              case RegistrationState.INFORMATION_MISSING:
                this.widget.setRegistrationInformationMissing();
                this.state = PricePlaceholderState.REGISTRATION_INFORMATION_MISSING;
                break;
              case RegistrationState.FINISHED:
                this.widget.setRequest();
                this.state = PricePlaceholderState.ON_REQUEST;
                break;
              case eh.RegistrationState.UNKNOWN:
                this.widget.setLogin();
                this.state = PricePlaceholderState.NEEDS_LOGIN;
                break;
            }
          })
          .catch(err => console.error('priceInfo.code 403 -> getRegistrationState', err))
        ;
      }
      else if (priceInfo?.code === '404') {
        this.widget.setNoPrice();
        this.widget.setDebugInfo(priceInfo?.code, priceInfo?.msg);
        this.state = PricePlaceholderState.NO_PRICE;
      }
      else {
        this.widget.setUnavailable();
        this.widget.setDebugInfo(priceInfo?.code ?? '', priceInfo?.msg ?? '');
        this.state = PricePlaceholderState.UNAVAILABLE;
      }
    }
  }


  enum PricePlaceholderState {
    INITIAL,
    UNAVAILABLE,
    LOADING,
    NEEDS_LOGIN,
    ON_REQUEST,
    SHOWING_PRICE,
    NO_PRICE,
    INITIAL_LAZY,
    REGISTRATION_IN_PROGRESS,
    REGISTRATION_INFORMATION_MISSING
  }


  enum PriceVisibility {
    //NOBODY = 'NOBODY',
    ANONYMOUS = 'ANONYMOUS',
    LOGGED_IN = 'LOGGED_IN'
  }


  interface PriceWidget {
    setDebugInfo(code: string, message: string | undefined): void;
    setLoading(): void;
    setLogin(): void;
    setNoPrice(): void;
    setRequest(): void;
    setRegistrationInProgress(): void;
    setRegistrationInformationMissing(): void;
    setUnavailable(): void;
  }


  class PriceAmountControl implements PriceWidget {

    private $amount: JQuery<HTMLElement>;
    private price: PriceDisplayWidget;

    private statePrice: boolean;
    private priceInfo: PriceServiceResponse_Material;
    private isConfiguredPrice: boolean;

    constructor(private readonly $el: JQuery<HTMLElement>,
                private readonly materialNumber: string,
                private readonly config: ProductPricingConfiguration) {
      const $numericStepper = this.$amount = $('.eh-numeric-stepper', $el);
      this.$amount = $('.eh-numeric-stepper--input', $numericStepper);
      $numericStepper.on('change', (_$event: JQuery.TriggeredEvent) => {
        const info = this.calculatePrice();
        if (info) {
          this.triggerEvent(PricePlaceholderState.SHOWING_PRICE, info);
        }
      });
      const $widgetEl = $('.eh-price-widget', $el);
      this.price = new PriceDisplayWidget($widgetEl);
    }

    setDebugInfo(code: string, message: string | undefined): void {
      this.$el.attr('data-pricing-service-code', code);
      if (message) {
        this.$el.attr('data-pricing-service-message', message);
      }
    }

    setLoading(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.LOADING);
      this.price.setLoading();
    }

    setLogin(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.NEEDS_LOGIN);
      this.price.setLogin();
    }

    setNoPrice(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.NO_PRICE);
      this.price.setNoPrice();
    }

    setPrice(priceInfo: PriceServiceResponse_Material, isConfigured = false): void {
      this.priceInfo = priceInfo;
      this.isConfiguredPrice = isConfigured;
      this.statePrice = true;
      const info = this.calculatePrice();
      if(info) {
        this.triggerEvent(PricePlaceholderState.SHOWING_PRICE, info);
      }
    }
  
    setRequest(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.ON_REQUEST);
      this.price.setRequest();
    }
  
    setRegistrationInProgress(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.REGISTRATION_IN_PROGRESS);
      this.price.setRegistrationInProgress();
    }

    setRegistrationInformationMissing(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.REGISTRATION_INFORMATION_MISSING);
      this.price.setRegistrationInformationMissing();
    }

    setUnavailable(): void {
      this.statePrice = false;
      this.triggerEvent(PricePlaceholderState.UNAVAILABLE);
      this.price.setUnavailable();
    }

    protected calculatePrice():PriceAmountInfo | null {
      if (!this.statePrice) {
        return null;
      }
      const amountVal = this.$amount.val();
      const currentAmount = typeof amountVal === 'string' ? parseInt(amountVal, 10) : 0;
      if (currentAmount === 0 || isNaN(currentAmount)) {
        this.setUnavailable();
        return null;
      }
      const currentScale = this.priceInfo.scale?.filter(
        (scale, index) => index < this.config.priceScaleMaxLevel
          && parseInt(scale.fromqty, 10) <= currentAmount)
        .sort((a, b) =>
          parseInt(b.fromqty, 10) - parseInt(a.fromqty, 10))[0];
      const unitPrice = parseFloat(currentScale ? currentScale.p.v : this.priceInfo.price);
      if (isNaN(unitPrice)) {
        this.setUnavailable();
        return null;
      }
      const total = Math.round(currentAmount * unitPrice * 100) / 100;
      const currency = currentScale ? currentScale.p.c : this.priceInfo.currency;
      const formatted = new Intl.NumberFormat(this.config.priceFormatLocale,
          { style: 'currency', currency: currency }).format(total);
      this.price.setPrice(this.isConfiguredPrice ? '' : this.config.fromPricePrefix, formatted);
      return {
        variant: this.priceInfo.variant,
        amount : currentAmount,
        unitPrice : unitPrice,
        totalPrice : total,
        currency : currency,
        locale : this.config.priceFormatLocale,
        prefix : this.isConfiguredPrice ? '' : this.config.fromPricePrefix,
        formatted: formatted
      };
    }
    
    
    private triggerEvent(state: PricePlaceholderState, info?:PriceAmountInfo|null) {
      const event = jQuery.Event(ProductPricing.EventIdProductPriceUpdate) as ProductPriceUpdateEvent;
      event.state = state;
      event.materialNumber = this.materialNumber;
      event.configComplete = this.isConfiguredPrice;
      if (info) {
        event.variant = info.variant;
        event.amount = info.amount;
        event.pricePrefix = info.prefix;
        event.locale = info.locale;
        event.unitPrice = info.unitPrice;
        event.totalPrice = info.totalPrice;
        event.currency = info.currency;
        event.priceFormatted = info.formatted;
      }
      $(':root').trigger(event);
    }

  }

  interface PriceAmountInfo {
    variant: string;
    amount: number;
    unitPrice: number;
    totalPrice: number;
    currency: string;
    locale: string,
    prefix: string;
    formatted: string;
  }

  class PriceDisplayWidget implements PriceWidget {

    private elements: {
      loadingView: JQuery<HTMLElement>,
      unavailableView: JQuery<HTMLElement>,
      loginView: JQuery<HTMLElement>,
      noPriceView: JQuery<HTMLElement>,
      priceView: JQuery<HTMLElement>,
      requestView: JQuery<HTMLElement>,
      registrationInProgressView: JQuery<HTMLElement>,
      registrationInformationMissingView: JQuery<HTMLElement>
    };

    constructor(protected readonly $el: JQuery<HTMLElement>) {
      this.elements = {
        loadingView: $('.eh-price-widget--loading-message', $el),
        unavailableView: $('.eh-price-widget--unavailable-message', $el),
        loginView: $('.eh-price-widget--login-message', $el),
        noPriceView: $('.eh-price-widget--no-price', $el),
        priceView: $('.eh-price-widget--price', $el),
        requestView: $('.eh-price-widget--request-message', $el),
        registrationInProgressView: $('.eh-price-widget--registration-in-progress-message', $el),
        registrationInformationMissingView: $('.eh-price-widget--registration-information-missing-message', $el)
      };
    }

    setDebugInfo(code: string, message: string | undefined): void {
      this.$el.attr('data-pricing-service-code', code);
      if (message) {
        this.$el.attr('data-pricing-service-message', message);
      }
    }

    public setLoading() {
      this.hideAll();
      this.elements.loadingView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }

    public setUnavailable() {
      this.hideAll();
      this.elements.unavailableView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }

    public setLogin() {
      this.hideAll();
      this.elements.loginView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }

    public setNoPrice() {
      this.hideAll();
      this.elements.noPriceView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }

    public setPrice(prefix: string, priceFormatted: string) {
      this.hideAll();
      $('.eh-price-widget--price-prefix', this.elements.priceView).text(prefix);
      $('.eh-price-widget--price-formatted', this.elements.priceView).text(priceFormatted);
      this.elements.priceView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }
  
    public setRequest() {
      this.hideAll();
      this.elements.requestView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }
  
    public setRegistrationInProgress() {
      this.hideAll();
      this.elements.registrationInProgressView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }
  
    public setRegistrationInformationMissing() {
      this.hideAll();
      this.elements.registrationInformationMissingView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }

    private hideAll() {
      Object.keys(this.elements).forEach(key => {
        //@ts-ignore
        this.elements[key].toggleClass(ProductPricing.CLASS_HIDDEN, true);
      });
    }

  }

  /** Listen for #ProductPriceUpdateEvent and adjust display */
  class PriceAmountMirrorWidget extends PriceDisplayWidget {
    public static readonly SELECTOR_MIRROR = ".marker-price-mirror";

    constructor($el: JQuery<HTMLElement>, private readonly materialNumber:string, private mimicPriceDisplayWidget:boolean) {
      super($el);
      $(':root').on(ProductPricing.EventIdProductPriceUpdate, (e) => {
        this.priceUpdate(e as ProductPriceUpdateEvent);
      });
    }
    
    public isMimicPriceDisplayWidget() {
      return this.mimicPriceDisplayWidget;
    }
    
    private priceUpdate(e: ProductPriceUpdateEvent) :void {
      ProductPricing.logger.debug('priceUpdate', e, this);
      
      if (e.materialNumber !== this.materialNumber) {
        return;
      }
      
      if (e.configComplete) {
        this.mimicPriceDisplayWidget = false;
      }
      
      if (this.isMimicPriceDisplayWidget()) {
        // behave as standard pricing widget, unless quickselect was completed or preconfigured
        return;
      }
      
      switch (e.state) {
        case PricePlaceholderState.LOADING:
          this.setLoading();
          break;
        case PricePlaceholderState.INITIAL:
          this.setNoPrice();
          break;
        case PricePlaceholderState.UNAVAILABLE:
          this.setUnavailable();
          break;
        case PricePlaceholderState.NEEDS_LOGIN:
          this.setLogin();
          break;
        case PricePlaceholderState.NO_PRICE:
          this.setNoPrice();
          break;
        case PricePlaceholderState.ON_REQUEST:
          this.setRequest();
          break;
        case PricePlaceholderState.REGISTRATION_IN_PROGRESS:
          this.setRegistrationInProgress();
          break;
        case PricePlaceholderState.REGISTRATION_INFORMATION_MISSING:
          this.setRegistrationInformationMissing();
          break;
        case PricePlaceholderState.SHOWING_PRICE:
          super.setPrice(e.pricePrefix!, e.priceFormatted!);
          break;
      }
    }
    
  }


  class PriceOptionDisplayWidget implements PriceWidget {

    private elements: {
      priceView: JQuery<HTMLElement>
    };

    constructor(private readonly $el: JQuery<HTMLElement>) {
      this.elements = {
        priceView: $el.selfOrFind('.eh-price-option-widget--price')
      };
    }

    setDebugInfo(code: string, message: string | undefined): void {
    }

    setLoading(): void {
    }

    setLogin(): void {
      this.hideAll();
    }

    setNoPrice(): void {
      this.hideAll();
    }

    setUnavailable(): void {
      this.hideAll();
    }

    public setPrice(priceFormatted: string) {
      this.hideAll();
      this.elements.priceView.selfOrFind('.eh-price-option-widget--price').text(priceFormatted);
      this.elements.priceView.toggleClass(ProductPricing.CLASS_HIDDEN, false);
    }
  
    setRequest(): void {
      this.hideAll();
    }

    setRegistrationInProgress(): void {
      this.hideAll();
    }

    setRegistrationInformationMissing(): void {
      this.hideAll();
    }

    private hideAll() {
      Object.keys(this.elements).forEach(key => {
        //@ts-ignore
        this.elements[key].toggleClass(ProductPricing.CLASS_HIDDEN, true);
      });
    }

  }


  class PricingService {

    private dummyTokenProvider = new Promise<JWToken>((resolve) => resolve(null))
    private serviceResponseCache: {[key: string]: Promise<PriceServiceResponse>} = Object.create(null);

    constructor(private readonly config: ProductPricingConfiguration, private nebpTokenProvider: () => Promise<JWToken>) {
    }

    public getConfig(): ProductPricingConfiguration {
      return this.config;
    }

    protected buildRequest(materialNumber: string | string[],
                           token: JWToken, materialOptions?: MaterialOptions,
                           optionPrices?: boolean
                          ): JQuery.AjaxSettings<PriceServiceResponse> {
      const settings: JQuery.AjaxSettings<PriceServiceResponse> = {
        'dataType': 'json',
        'timeout': 30_000
      };
      const url = this.config.serviceUrl +
          (token ?
              (materialOptions ? this.config.endpointPath.getCustNetPrice : (optionPrices ?
                  this.config.endpointPath.getCustOptionsPrice : this.config.endpointPath.getCustPrice)) :
              (materialOptions ? this.config.endpointPath.getAnonNetPrice : (optionPrices ?
                  this.config.endpointPath.getAnonOptionsPrice : this.config.endpointPath.getAnonPrice)));
      const qp: {[key: string]: string} = {'matnr': Array.isArray(materialNumber) ? materialNumber.join(',') : materialNumber };
      if (optionPrices) {
        qp.includeMinprice = 'false';
      }
      if (token) {
        settings.headers = {'jw_token': token};
      }
      else {
        qp.salesorg = this.config.salesOrganization;
      }
      if (this.config.siteUrl) {
        qp.siteURL = this.config.siteUrl;
      }
      if (materialOptions) {
        settings.contentType = 'application/json';
        settings.data = JSON.stringify(materialOptions);
        settings.method = 'POST';
        settings.processData = false;
      }
      else {
        settings.method = 'GET';
      }
      settings.url = eh.URLHelper.buildUrl(url, eh.URLHelper.buildQueryString(qp));
      return settings;
    }

    public getPrice(materialNumber: string | string[], priceVisibility: PriceVisibility): Promise<PriceServiceResponse> {
      return (this.config.awaitNebpToken ? this.nebpTokenProvider() : this.dummyTokenProvider)
        .then(token => {
          if (priceVisibility === PriceVisibility.LOGGED_IN && !token) {
            // shortcut if login is required but no token available
            return Promise.resolve({'artificial': true, 'rsp': {'res': [{'code': '403', 'matnr': materialNumber}]}});
          }
          const request = this.buildRequest(materialNumber, token);
          return $.ajax(request);
        }, (reason) => {
          ProductPricing.logger.warn('Failed to get nebp token', reason);
          return Promise.reject('failed to get nebp token');
        });
    }

    public getPriceConfigured(materialNumber: string, priceVisibility: PriceVisibility, materialOptions: MaterialOptions): Promise<PriceServiceResponse> {
      return (this.config.awaitNebpToken ? this.nebpTokenProvider() : this.dummyTokenProvider)
        .then(token => {
          if (priceVisibility === PriceVisibility.LOGGED_IN && !token) {
            // shortcut if login is required but no token available
            return Promise.resolve({'artificial': true, 'rsp': {'res': [{'code': '403', 'matnr': materialNumber}]}});
          }
          const request = this.buildRequest(materialNumber, token, materialOptions);
          return $.ajax(request);
        }, (reason) => {
          ProductPricing.logger.warn('Failed to get nebp token', reason);
          return Promise.reject('failed to get nebp token');
        });
    }

    public getPriceForOptions(materialNumber: string, priceVisibility: PriceVisibility): Promise<PriceServiceResponse> {
      if (materialNumber in this.serviceResponseCache) {
        return this.serviceResponseCache[materialNumber];
      }

      this.serviceResponseCache[materialNumber] = (this.config.awaitNebpToken ? this.nebpTokenProvider() : this.dummyTokenProvider)
        .then(token => {
          if (priceVisibility === PriceVisibility.LOGGED_IN && !token) {
            // shortcut if login is required but no token available
            return Promise.resolve({'artificial': true, 'rsp': {'res': [{'code': '403', 'matnr': materialNumber}]}} as PriceServiceResponse);
          }
          const request = this.buildRequest(materialNumber, token, undefined, true);
          return $.ajax(request);
        }, (reason) => {
          ProductPricing.logger.warn('Failed to get nebp token', reason);
          return Promise.reject('failed to get nebp token');
        });

      return this.serviceResponseCache[materialNumber];
    }

  }


  type ProductPricingConfiguration = {
    'awaitNebpToken': boolean,
    'endpointPath': {
      'getAnonNetPrice': string,
      'getAnonOptionsPrice': string,
      'getAnonPrice': string,
      'getCustNetPrice': string,
      'getCustOptionsPrice': string,
      'getCustPrice': string

    },
    'fromPricePrefix': string,
    'priceScaleMaxLevel': number,
    'piecesLabel': string,
    'priceFormatLocale': string,
    'salesOrganization': string,
    'serviceUrl': string,
    'siteUrl': string | undefined,
    'unitLabels': {
      [key: string]: string
    }
  };


  type MaterialOptions = {
    o: string[]
  };

  export type PriceScaleInfo = {
    range: string,
    price: string
  };

  type PriceServiceResponse = {
    "msgs"?: PriceServiceResponse_Message[]
    "rsp"?: {
      "res": Array<PriceServiceResponse_Material | PriceServiceResponse_Options | PriceServiceResponse_Failure> | undefined
    },
    "artificial"?: boolean
  };

  type PriceServiceResponse_Base = {
    "matnr": string
  };

  type PriceServiceResponse_Success = PriceServiceResponse_Base & {
    "cdate": string
  };

  type PriceServiceResponse_Material = PriceServiceResponse_Success & {
    "currency": string,
    "price": string,
    "scale"?: PriceServiceResponse_Scale[],
    "variant": string
  };

  type PriceServiceResponse_Options = PriceServiceResponse_Success & {
    "options": PriceServiceResponse_OptionPrice[];
  };

  type PriceServiceResponse_Failure = PriceServiceResponse_Base & {
    "code": '401' | '403' | '404',
    "msg"?: string
  };

  type PriceServiceResponse_Scale = {
    "fromqty": string,
    "p": PriceServiceResponse_Price
  };

  type PriceServiceResponse_Price = {
    "c": string,
    "v": string
  };

  type PriceServiceResponse_Message = {
    "desc": string,
    "product": string,
    "txt": string,
    "type": string
  };

  type PriceServiceResponse_OptionPrice_Base = {
    "o": string
  }

  type PriceServiceResponse_OptionPrice_NoPrice = PriceServiceResponse_OptionPrice_Base & {
    "v": 'null' | undefined
  }

  type PriceServiceResponse_OptionPrice_Standard = PriceServiceResponse_OptionPrice_Base & {
    "v": string,
    "c": string
  }

  type PriceServiceResponse_OptionPrice_Dependend = PriceServiceResponse_OptionPrice_Standard & {
    "l": number,
    "u": string
  }

  type PriceServiceResponse_OptionPrice = PriceServiceResponse_OptionPrice_Standard
    | PriceServiceResponse_OptionPrice_Dependend | PriceServiceResponse_OptionPrice_NoPrice; 

  export interface ProductPriceUpdateEvent extends JQuery.TriggeredEvent<HTMLElement> {
    state: PricePlaceholderState,
    materialNumber: string, // fix material number of product
    variant: string; // configuration variant price is valid for; Represents quickselect state? Only if configComplete!
    configComplete?: boolean, // varaiant is completely configured
    amount?: number, // how many?
    pricePrefix?:string, // before configComplete mybe 'from'
    priceFormatted?:string, //
    unitPrice?: number, // price per unit (at selected amount)
    totalPrice?: number, // total price for amount of products
    currency?: string, // prices currency 
    locale?:string, // formatting locale
  }
  
}

